import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import FeatureImage from './FeatureImage';

const BreakdownHeader = ({ children, price }) => {
  const flags = useFlags();

  const isTitleVariationShown = (flags.checkoutBreakdownVariation || 'control').includes('shipping');
  const originalPrice = (flags.checkoutOriginalPrice || '60') === '60' ? 60 : 150;

  return (
    <>
      <div className="w-2/3 max-h-[120px]">
        <FeatureImage />
      </div>
      {isTitleVariationShown ? (
        <div key="breakdown-variant" className="grid grid-cols-2 w-full font-montserrat font-bold text-red-600">
          <h1 className="text-lg col-span-2 text-center w-full pt-2">
            ${~~(originalPrice - price)} Off
          </h1>
          <h1 className="text-lg col-span-2 text-center w-full">
            &
          </h1>
          <h1 className="text-lg col-span-2 text-center w-full pb-2">
            Free Shipping
          </h1>
        </div>
      ) : children}
    </>
  )
};

const ItemRow = ({ origPrice }) => {
  const flags = useFlags();

  const isTitleVariationShown = (flags.checkoutBreakdownVariation || 'control').includes('shipping');
  const originalPrice = (flags.checkoutOriginalPrice || '60') === '60' ? 60 : 150;
  
  return (
    <div className="text-left pb-1 grid grid-cols-2 col-span-2 w-full">
      <p className="pr-3 text-left text-base">
        {isTitleVariationShown ? 'At-home test & consultation:' : 'Subtotal:'}
      </p>
      <p className="text-right text-base">${originalPrice}</p>
    </div>
  );
};

const DiscountRow = ({ price = 45 }) => {
  const flags = useFlags();

  const originalPrice = (flags.checkoutOriginalPrice || '60') === '60' ? 60 : 150;

  return (
    <div className="text-left pb-1 grid grid-cols-2 col-span-2 w-full">
      <p className="text-left text-base">Discount:</p>
      <p className="text-right text-base text-red-600">
        ${~~(originalPrice - price)} OFF
      </p>
    </div>
  );
}

const ShippingRow = ({ children }) => {
  return (
    <div className="text-left pb-1 grid grid-cols-2 col-span-2 border-b border-darkGrey w-full">
      <p className="pr-4 text-left text-base">Shipping:</p>
      <p className="text-right text-base uppercase">{children}</p>
    </div>
  );
}

export default function CheckoutAssessmentFeature(props) {
  let {
    discount,
    tracker,
    price,
    checkoutFeature,
    image,
    shipping,
    disclaimer,
    origPrice,
  } = props.priceObj;

  mixpanel.init(process.env.MIXPANEL_KEY);
  useEffect(() => {
    mixpanel.track(tracker);
  }, []);

  return (
    <div className="grid grid-cols-2 py-4 gap-0 px-1 justify-items-center border-t border-darkGrey">
      <BreakdownHeader price={price}>
        {checkoutFeature}
      </BreakdownHeader>
      <ItemRow origPrice={origPrice} />
      <DiscountRow price={price} />
      <ShippingRow>
        FREE
      </ShippingRow>
      <div className="text-left pt-1 grid grid-cols-2 col-span-2 w-full">
        <p className="text-left text-base">Total:</p>
        <p className="text-right text-base">${price}</p>
      </div>
    </div>
  );
}
