import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function TrustImages() {
  return (
    <div className="grid grid-cols-2">
      <div className="flex content-center p-4 w-2/3">
        <a
          href="https://www.legitscript.com/websites/?checker_keywords=honehealth.com"
          target="_blank"
          rel="noreferrer"
          title="Verify LegitScript Approval"
        >
          <StaticImage
            alt="LegitScript approved"
            src={`https://static.legitscript.com/seals/10842626.png`}
            className="self-center "
          />
        </a>
        <a
          href="https://www.trustpilot.com/review/honehealth.com"
          target="_blank"
          rel="noreferrer"
          title="Take a look at our reviews"
        >
          <StaticImage
            alt="Trustpilot score"
            src="../images/trustpilot.png"
            className="self-center"
          />
        </a>
      </div>
    </div>
  );
}
