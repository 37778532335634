import axios from 'axios';

const reqHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Authorization: process.env.TT_API_KEY,
};

let API_URL = process.env.GATSBY_API_URL || 'https://dev-api.honehealth.com';

const kitAddedToCart = async (email, first, last, state) => {
  const honePayload = {
    email: email,
    firstName: first,
    lastName: last,
    state: state,
  };
  await axios
    .post(
      `${API_URL}/api/events/custio/kitAddedToCart`,
      JSON.stringify(honePayload),
      {
        headers: reqHeaders,
      }
    )
    .catch((err) => console.log(err));
};

const createHoneOrder = async (
  email,
  ip,
  paymentMethod,
  transactionToken,
  customerProfileToken,
  ccLastFour,
  shippingAddressOne,
  shippingAddressTwo,
  city,
  state,
  zipCode,
  phone,
  firstName,
  lastName,
  medium,
  source,
  campaign,
  hearAboutUs,
  paymentAmount,
  productId,
  branch
) => {
  const honePayload = {
    customerEmail: email.trim(),
    affiliateId: 1,
    lastIpAddress: ip,
    paymentProfileToken: customerProfileToken.toString(),
    paymentProviderTypeId: 6,
    paymentToken: paymentMethod.toString(),
    ccLastFour: ccLastFour,
    productId: productId,
    paymentAmount: paymentAmount,
    transactionToken: transactionToken.toString(),
    shippingAddress: shippingAddressOne,
    shippingAddress2: shippingAddressTwo,
    shippingCity: city,
    shippingStateProvince: state,
    zipPostalCode: zipCode,
    phoneNumber: phone,
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    medium: medium,
    adSource: source,
    campaign: campaign,
    hearAboutUs: hearAboutUs,
    createOktaAccount: false,
    source: window.location.origin,
    ab_branch: branch || '',
  };

  // appInsights.trackEvent({
  //   name: 'Create Hone Order',
  //   properties: honePayload,
  // });
  const res = await axios
    .put(
      `${API_URL}/api/laborders/CreateNopCustomerKitOrder`,
      JSON.stringify(honePayload),
      {
        headers: reqHeaders,
      }
    )
    .catch((err) => console.log(err));

  return res ? res.data : '';
};

const createPaymentIntent = async (paymentAmount) => {
  const res = await axios
    .get(
      `${API_URL}/api/payments/createstripepaymentintent/${paymentAmount}?source=${encodeURIComponent(window.location.origin)}`,
      {
        headers: reqHeaders,
      }
    )
    .catch((err) => console.log(err));

  return res ? res.data : '';
};

const createCustomer = async (first, last, email) => {
  const honePayload = {
    email: email.trim(),
    firstName: first.trim(),
    lastName: last.trim(),
    paymentProcessorId: 6,
  };

  // appInsights.trackEvent({
  //   name: 'Create Customer',
  //   properties: honePayload,
  // });

  const res = await axios
    .post(
      `${API_URL}/api/payments/createprofile`,
      JSON.stringify(honePayload),
      {
        headers: reqHeaders,
      }
    )
    .catch((err) => console.log(err));

  return res ? res.data.customerProfileToken : '';
};

const attachPaymentMethod = async (
  first,
  last,
  email,
  customerProfileToken,
  paymentMethod
) => {
  const honePayload = {
    email: email.trim(),
    firstName: first.trim(),
    lastName: last.trim(),
    paymentProcessorId: 6,
    customerProfileToken: customerProfileToken,
    paymentToken: paymentMethod,
  };
  // appInsights.trackEvent({
  //   name: 'Attach Customer to Payment',
  //   properties: honePayload,
  // });

  const res = await axios
  .post(
    `${API_URL}/api/payments/UpdateStripeCustomerPayment`,
    JSON.stringify(honePayload),
    {
      headers: reqHeaders,
    }
  ).catch((err) => console.log(err));
  
  return res ? res.data : '';
}

export {
  kitAddedToCart,
  createHoneOrder,
  createPaymentIntent,
  createCustomer,
  attachPaymentMethod,
};
