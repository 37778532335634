import React from 'react';
import { CondenseTextField, Select, Option } from '@honehealth/components';

const OPTIONS = [
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'Instagram',
    value: 'instagram',
  },
  {
    label: 'Google',
    value: 'google',
  },
  {
    label: 'TV',
    value: 'tv',
  },
  {
    label: 'Article',
    value: 'article',
  },
  {
    label: 'Friend',
    value: 'friend',
  },
  {
    label: 'TikTok',
    value: 'tiktok',
  },
  {
    label: 'Youtube',
    value: 'youtube',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export function validateSurvey(value, otherValue, isRequired = false) {
  if (isRequired && !value) {
    return false;
  }

  if (value === 'other' && !otherValue) {
    return false;
  }

  return true;
}

const SurveyDropdown = ({ value = '', onChange = () => {}, otherValue = '', otherOnChange = () => {} }) => {
  return (
    <div className="w-full flex flex-col space-y-2">
      <Select
        key="survey-dropdown"
        className="w-full"
        id="survey-dropdown"
        maxOptionsHeight={40}
        defaultValue=""
        value={value}
        onChange={(e) => {
          e.preventDefault();
          onChange(e);
        }}
      >
        <>
          <Option value="" disabled>
            How did you hear about us?
          </Option>
          {OPTIONS.map(({ label, value }) => {
            return (
              <Option
                className="w-full"
                key={value}
                value={value}
              >
                {label}
              </Option>
            );
          })}
        </>
      </Select>
      {value === 'other' && (
        <CondenseTextField
          id="survey-other"
          className="w-full"
          label="Where did you hear from us?"
          value={otherValue}
          onChange={(e) => {
            e.preventDefault();
            otherOnChange(e);
          }}
        />
      )}
    </div>
  );
}

export default SurveyDropdown;
