import React, { useState, useEffect, useContext } from 'react';
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { navigate } from 'gatsby';
import { usaStates } from '../components/utils/Data';
import toast, { Toaster } from 'react-hot-toast';
import { CondenseTextField, Button, Select, Option } from '@honehealth/components';
import mixpanel from 'mixpanel-browser';
import NavBar from '../components/Navbar';
import CTAButton from '../components/CTAButton';
import SurveyDropdown, { validateSurvey } from '../components/SurveyDropdown';

import { myContext } from '../components/utils/ContextProvider';
import {
  checkoutFormValidation,
  privateCheckoutRoute,
  pushDataToGTM,
  urlDataDistributor,
} from '../components/utils/Utils';
import {
  kitAddedToCart,
  createPaymentIntent,
  createHoneOrder,
  createCustomer,
  attachPaymentMethod,
} from '../components/utils/OrderUtils';
import TrustImages from '../components/TrustImages';
import CheckoutAssessmentFeature from '../components/CheckoutAssessmentFeature';
import { facebookEvent } from '../components/utils/MonitorUtils';
import { useFlag } from '../utils/useFlag';
import { useLDClient } from 'gatsby-plugin-launchdarkly';

export default function OnePageCheckoutSplit(props) {
  return <OnePageCheckout feature="checkout-reorder-billing-layout" treatment="off" />
}

function OnePageCheckout(props) {
  const globalContext = useContext(myContext);
  const client = useLDClient();

  const surveyVariation = useFlag('checkoutSurvey');
  const shouldShowSurvey = !!(surveyVariation || 'hide').match(/^(show|required)$/);
  const isSurveyRequired = (surveyVariation || 'hide') === 'required';

  //payment state
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [blocked, setBlocked] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [customerProfileToken, setCustomerProfileToken] = useState('');

  //order and form state
  const [name, setName] = useState(`${globalContext.firstName || ''} ${globalContext.lastName || ''}`.trim());
  const [firstName, setFirstName] = useState(globalContext.firstName || '');
  const [lastName, setLastName] = useState(globalContext.lastName || '');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [surveyValue, setSurveyValue] = useState('');
  const [surveyOtherValue, setSurveyOtherValue] = useState('');

  const stripe = useStripe();
  const elements = useElements();
  mixpanel.init(process.env.MIXPANEL_KEY);

  let location = typeof window !== 'undefined' ? window.location.href : '';
  const branch = props.feature + '=' + props.treatment;

  const priceValue = urlDataDistributor(location, 'checkout');

  const getCustomerData = async () => {
    setSelectedState(globalContext.selectedState);
    setFirstName(globalContext.firstName || '');
    setLastName(globalContext.lastName || '');

    await createPaymentIntent(priceValue.stripePrice).then((secret) => {
      setClientSecret(secret);
    });

    await createCustomer(firstName, lastName, globalContext.emailAddress).then(
      (customerId) => {
        setCustomerProfileToken(customerId);
      }
    );
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    localStorage.setItem('phoneNumber', phone);
    setProcessing(true);

    if (shouldShowSurvey && !validateSurvey(surveyValue, surveyOtherValue, isSurveyRequired)) {
      toast('Please select an option for how you heard about us!')
      setProcessing(false);
      return;
    }

    if (clientSecret !== '' && customerProfileToken.includes('cus')) {
      if (
        checkoutFormValidation(
          firstName,
          lastName,
          zipCode,
          city,
          phone,
          address1,
          selectedState
        )
      ) {
        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
          receipt_email: globalContext.emailAddress,
          shipping: {
            name: `${firstName} ${lastName}`.trim(),
            address: {
              line1: `${address1}`,
              line2: `${address2}`,
              city: `${city}`,
              state: `${selectedState}`,
              postal_code: `${zipCode}`,
              country: 'US',
            },
          },
          setup_future_usage: 'off_session',
        });
        if (payload.error) {
          setError(`Payment failed ${payload.error.message}`);
          toast('There was an error processing your payment');
          setProcessing(false);
        } else {
          let dedupeId = facebookEvent(globalContext, 'Purchase');
          await attachPaymentAndPlaceOrder(payload.paymentIntent.payment_method);
          pushDataToGTM({
            event: 'Purchase',
            firstName,
            lastName,
            email: globalContext.emailAddress,
            dedupeId: dedupeId,
          });
          mixpanel.track('Placed Order', {
            branch,
            'First Name': firstName,
            'Last Name': lastName,
            Email: globalContext.emailAddress,
          }); 
          client.track('purchase-complete', {
            sku: 'LABTEST-HRT-1',
          }, 45);
          setProcessing(false);
          setSucceeded(true);
          navigate('/confirmation');
        }
      } else {
        toast('Please complete all fields to continue!');
        setProcessing(false);
      }
    } else {
      setProcessing(false);
      setBlocked(true);
      setDisabled(true);
      toast('Adblock/VPN is blocking us from processing your payment');
      console.log(
        'Adblock or VPN is blocking our API from processing your payment'
      );
    }
  };

  const attachPaymentAndPlaceOrder = async (pm, wp) => {
    setError(null);

    //wallet payment 
    if (wp){
      const {
        shippingAddress,
        paymentMethod,
        payerPhone,
      } = wp;
      let wpFirstName, wpLastName;

      if (shippingAddress.recipient) {
        const recipientNames = shippingAddress.recipient.split(' ');
        const nonEmptyNames = recipientNames.filter(name => name.trim() !== '');

        if (nonEmptyNames.length >= 2) {
          wpFirstName = nonEmptyNames[0];
          //account for the case of a middle name 
          wpLastName = nonEmptyNames[nonEmptyNames.length - 1];
        }
      }

      // If wpFirstName and wpLastName are not assigned values from shippingAddress recipient, use billing name
      if (!wpFirstName || !wpLastName) {
        const billingDetailsNames = wp.paymentMethod.billing_details.name.split(' ') || [];
        wpFirstName = billingDetailsNames[0];
        wpLastName = billingDetailsNames[billingDetailsNames.length - 1];
      }
      
      const { city, postalCode, region, addressLine } = shippingAddress || {};
      const [wpAddress1, wpAddress2] = addressLine || [];
      const wpLast4 = paymentMethod?.card?.last4;

      let wpData = {
        wpCity: city,
        wpPostalCode: postalCode,
        wpRegion: region,
        wpAddress1,
        wpAddress2,
        wpLast4,
        wpPayerPhone: payerPhone,
        wpFirstName,
        wpLastName,
      };

      //attach stripe customer to payment
      await attachPaymentMethod(
        wpFirstName,
        wpLastName,
        globalContext.emailAddress,
        customerProfileToken,
        pm
      );

      try {
        const order = await createHoneOrder(
          globalContext.emailAddress,
          '127.001.001',
          pm,
          clientSecret.substring(0, 27),
          customerProfileToken,
          wpData.wpLast4,
          wpData.wpAddress1,
          wpData.wpAddress2 ?? '',
          wpData.wpCity,
          wpData.wpRegion,
          wpData.wpPostalCode,
          wpData.wpPayerPhone,
          wpData.wpFirstName,
          wpData.wpLastName,
          localStorage.getItem('medium'),
          localStorage.getItem('source'),
          localStorage.getItem('campaign'),
          'disabled',
          priceValue.honePrice,
          priceValue.productId
        );
    
        var orderNum = order.externalOrderId;
        localStorage.setItem('num', orderNum);
        globalContext.changeOrderNumber(orderNum);
        return { wpFirstName, wpLastName }
      } catch (err) {
        mixpanel.track('Wallet Pay Data Error', {
          branch,
          Email: globalContext.emailAddress,
          honeError: err.message,
          data: wpData,
          billingName: wp.paymentMethod.billing_details.name     
        });
      };
    } else {
      //attach stripe customer to payment
      const custDetails = await attachPaymentMethod(
        firstName,
        lastName,
        globalContext.emailAddress,
        customerProfileToken,
        pm
      );

      const order = await createHoneOrder(
        globalContext.emailAddress,
        '127.001.001',
        pm,
        clientSecret.substring(0, 27),
        customerProfileToken,
        custDetails.creditCardNumber,
        address1,
        address2,
        city,
        selectedState,
        zipCode,
        phone,
        firstName,
        lastName,
        localStorage.getItem('medium'),
        localStorage.getItem('source'),
        localStorage.getItem('campaign'),
        'disabled',
        priceValue.honePrice,
        priceValue.productId
      );
  
      var orderNum = order.externalOrderId;
      localStorage.setItem('num', orderNum);
      globalContext.changeOrderNumber(orderNum);
    };

  };

  const handleName = (str) => {
    const words = str.trim().split(' ');
    const first = words[0];
    const last = words[words.length - 1];

    setFirstName(first);
    setLastName(last);
  };

  useEffect(() => {
    //if the user hasnt entered form data redirect to home
    privateCheckoutRoute('hasEnteredData', `/`);
    mixpanel.track('Got to Checkout', {
      branch,
    });
    //  generate payment intent, and create stripe customer as soon as the page loads
    getCustomerData();
    //tell customer.io the user has added a kit to their cart
    kitAddedToCart(
      globalContext.emailAddress,
      globalContext.firstName,
      globalContext.lastName,
      globalContext.selectedState
    );
  }, []);

  //this use effect is for the purpose of setting up a payment intent for apple/google wallet payments
  useEffect(() => {
    if (clientSecret !== '' && customerProfileToken.includes('cus')) {    
      if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: priceValue.stripePrice,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: true,
        shippingOptions: [
          //options that appear in the apple pay window
          {
            id: 'free-shipping',
            label: 'Free Shipping',
            detail: 'Its on us!',
            amount: 0,
          },
        ],
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (e) => {
            setProcessing(true);
              const payload = await stripe.confirmCardPayment(
                clientSecret,
                {
                  payment_method: e.paymentMethod.id,
                  setup_future_usage: 'off_session',
                },
                { handleActions: false }
              );
              if (payload.error) {
                e.complete('fail');
                setError(`Payment failed ${payload.error.message}`);
                toast('There was an error processing your payment');
                setProcessing(false);
              } else {
                e.complete('success');
                mixpanel.track('Used Wallet Pay', {
                  branch,
                });
                let dedupeId = facebookEvent(globalContext, 'Purchase');
                if (payload.paymentIntent.status === 'requires_action') {
                  stripe.confirmCardPayment(clientSecret);
                }  
                const { wpFirstName, wpLastName } = await attachPaymentAndPlaceOrder(payload.paymentIntent.payment_method, e);
                mixpanel.track('Placed Order', {
                  branch,
                  'First Name': wpFirstName,
                  'Last Name': wpLastName,
                  Email: globalContext.emailAddress,
                }); 
                pushDataToGTM({
                  event: 'Purchase',
                  firstName: wpFirstName,
                  lastName: wpLastName,
                  email: globalContext.emailAddress,
                  dedupeId: dedupeId,
                });
                client.track('purchase-complete', {
                  sku: 'LABTEST-HRT-1',
                }, 45);
                setProcessing(false);
                setSucceeded(true);
                navigate('/confirmation');
              }
          }
        );
      } else {
        setProcessing(false);
        setBlocked(true);
        setDisabled(true);
        toast('Adblock/VPN is blocking us from processing your payment');
        console.log(
          'Adblock or VPN is blocking our API from processing your payment'
        );
      }
    }
  }, [stripe, clientSecret, customerProfileToken]);

  //stripe wallet pay element
  const alternativePayment = (paymentRequest) => {
    if (paymentRequest) {
      return (
        <div className="py-2">
          <h1 className="text-lg col-span-2 text-black text-center w-full font-bold pb-2">
            -or-
          </h1>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      );
    }
  };

  return (
    <>
      <Toaster
        containerStyle={{ position: 'fixed' }}
        reverseOrder={false}
        toastOptions={{
          style: {
            color: 'rgba(252, 252, 250)',
            backgroundColor: 'rgba(239, 50, 25)',
            fontFamily: 'inter',
          },
        }}
      />
      <NavBar />
      <div className="min-h-screen flex items-center justify-center">
        <title>Checkout</title>
        <div className="bg-white p-2 w-full grid-cols-1 lg:w-2/3 lg:p-6 2xl:w-2/4 rounded shadow-2xl grid lg:grid-cols-2 gap-1">
          <form className="space-y-3 p-3 col-span-1">
            <h2 className="text-lg md:text-xl mb-2 text-black text-left border-b border-darkGrey">
              Shipping Information
            </h2>
            <div>
              <CondenseTextField
                id="name"
                className="w-full"
                label="Full Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  handleName(e.target.value);
                }}
              />
            </div>
            <div className="w-full grid gap-2 grid-cols-2">
              <CondenseTextField
                id="address-line-1"
                className="col-span-1"
                label="Address"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
              />
              <CondenseTextField
                id="address-line-2"
                className="col-span-1"
                label="Suite/Apt."
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
              />
            </div>
            <div>
            </div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <div>
                <CondenseTextField
                  id="city"
                  className="w-full"
                  label="City"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div>
                <Select
                  className="w-full"
                  id="state"
                  maxOptionsHeight={40}
                  defaultValue={globalContext.selectedState}
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  {usaStates.map((stateObj, index) => {
                    return (
                      <Option
                        className="w-full"
                        key={index}
                        value={stateObj.value}
                      >
                        {stateObj.label}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div>
                <CondenseTextField
                  id="zip"
                  className="w-full"
                  label="ZIP Code"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </div>
              <div>
                <CondenseTextField
                  id="phone"
                  className="w-full"
                  label="Phone #"
                  placeholder="Phone #"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            {shouldShowSurvey && (
              <SurveyDropdown
                value={surveyValue}
                onChange={(e) => setSurveyValue(e.target.value)}
                otherValue={surveyOtherValue}
                otherOnChange={(e) => setSurveyOtherValue(e.target.value)}
              />
            )}
          </form>
          <div className="order-last lg:order-2 grid p-1 grid-cols-1 col-span-1 lg:row-span-2">
            <div className="items-center p-2 row-span-1">
              <h1 className="text-lg md:text-xl col-span-2 text-black text-left">
                Checkout Summary
              </h1>
              <CheckoutAssessmentFeature priceObj={priceValue} />
              <div>
                <CTAButton
                  disabled={processing || disabled || succeeded}
                  id="submit"
                  onClick={
                    ((e) => {
                      e.preventDefault();
                      succeeded
                        ? navigate('/checkout/confirm-order')
                        : toast(error);
                    },
                    handleSubmit)
                  }
                >
                  <span id="button-text">
                    {processing ? 'Processing...' : 'SUBMIT ORDER'}
                  </span>
                </CTAButton>
                <TrustImages />
              </div>
            </div>
          </div>
          <form className="space-y-3 p-1 lg:order-last col-span-1">
            <h2 className="text-lg md:text-xl mb-2 text-black text-left border-b border-darkGrey">
              Payment
            </h2>
            <div className="grid grid-cols-1 gap-0 w-full ">
              <CardElement
                id="card-element"
                className="font-inter w-full border border-darkGrey p-3 leading-7"
                onChange={handleChange}
              />
              {alternativePayment(paymentRequest)}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
